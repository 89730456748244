<template>
    <div class="outer-wrapper">
        <div class="inner-wrapper">
            <b></b>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Void'
    };
</script>

<style scoped>
    .outer-wrapper {
        background: #000;
        height: 100vh;
        width: 100vw;
    }

    .inner-wrapper {
        filter: blur(8px);
        display: grid;
        place-content: center;
        width: 100vw;
        height: 100vh;
    }

    b, b:before, b:after {
        display: block;
        border-radius: 50%;
    }

    b {
        width: 50vmin;
        height: 51vmin;
        box-shadow: 1vmin 0 3vmin 2vmin #f50,
        inset -1vmin 0 3vmin 4vmin #f80,
        -4vmin 0 35vmin 0 #f60;
        animation: bh 10s linear infinite;
    }

    @keyframes bh {
        0% {
            transform: rotate(0deg)
        }
        100% {
            transform: rotate(-360deg)
        }
    }

    b:before, b:after {
        content: "";
        background: white;
        position: relative;
        top: 6vmin;
    }

    b:before {
        width: 3vmin;
        height: 3vmin;
        left: 40vmin;
        box-shadow: yellow 0 0 2vmin 2vmin,
        yellow 2vmin 4vmin 2vmin 0.5vmin;
    }

    b:after {
        width: 38vmin;
        height: 38vmin;
        opacity: 0.03;
        left: -14vmin;
    }
</style>